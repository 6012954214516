<loading-layout #loadingLayout loaderType="puntaje-small">
    <div class="row">
        <div class="col-md-4">
            <div class="avatar-container">
                <img *ngIf="usuario.avatar" class="avatar" alt="avatar" [src]="usuario.avatar | trustedurl" />
                <img
                    *ngIf="!usuario.avatar"
                    class="avatar"
                    alt="no avatar"
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
                />
            </div>
            <div class="nombre-container">
                <p>{{ usuario?.nombreCompleto() }}</p>
            </div>
            <div class="usuarioId-container" *ngIf="showUserId">
                <label>{{ "perfil.user_id" | t }}:</label>
                <p>{{ usuarioId }}</p>
            </div>
            <div class="establecimiento-container" *ngIf="enableShowEstablecimientoData">
                <label *ngIf="usuario?.establecimiento && !isZero">{{ params.establecimiento.label }}:</label>
                <label *ngIf="isZero">Sede:</label>
                <p>{{ usuario?.establecimiento }}</p>
            </div>
            <div class="email-container" *ngIf="!isPrivate">
                <label>{{ params.email.label }}:</label>
                <p>{{ usuario?.email }}</p>
            </div>
            <div class="fecha-nacimiento-container" *ngIf="!isPrivate">
                <label>{{ params.fecha_nacimiento.label }}:</label>
                <p>{{ usuario?.fecha_nacimiento ? "****" : ("perfil.fecha_indefinida" | t) }}</p>
            </div>
            <div *ngIf="enableShowCountryData && !isPrivate" class="pais-container">
                <div *ngFor="let idP of idPais">
                    <ng-container
                        *ngIf="
                            usuario['usuario_' + pais] && usuario['usuario_' + pais][idP.toLowerCase()];
                            else noIdPais
                        "
                    >
                        <label>{{ idPaisAliasMap ? idPaisAliasMap[idP.toLowerCase()] : idP }}:</label>
                        <p>
                            {{ usuario["usuario_" + pais][idP.toLowerCase()] }}
                        </p>
                    </ng-container>
                    <ng-template #noIdPais>
                        <label>{{ idP }}:</label>
                        <p>-</p>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="curso" class="curso">
                <label>{{ params.nivel_id.label }}:</label>
                <p>{{ curso | easyplaceholder: "{{'perfil.curso_indefinido'| t }}" }}</p>
            </div>
        </div>
        <div *ngIf="preferencia_carreras && preferencia_carreras.length > 0" class="col-md-8">
            <div class="preferencias-carrera" *ngFor="let preferencia of preferencia_carreras">
                <div class="carrera-container">
                    <label>{{ "perfil.preferencia_carrera" | t }} {{ preferencia.orden }}:</label>
                    <p>{{ preferencia.carrera.carrera }} | {{ preferencia.carrera.sede.sede }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="enableShowExtraData" class="col-md-4">
            <ng-content></ng-content>
        </div>
    </div>
</loading-layout>
